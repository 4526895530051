import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../styles/AddAccountDrawer.module.scss";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { colourStylesCurrency } from "./SelectCurrencyStyles";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

export default function AddAccountDrawer({ open, toggleDrawer, theme }) {
  const { t } = useTranslation();

  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          props.isSelected
            ? styles.custom_option_selected
            : styles.custom_option
        }
      >
        <div
          className={styles.option}
          style={{
            color: props.data.color,
            backgroundColor: props.data.background,
            width: "auto",
          }}
        >
          {props.data.label}
        </div>
      </div>
    );
  };

  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg
            width={"1.7vh"}
            height={"1.6vh"}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5003 6L8.83366 10L4.16699 6"
              stroke="#A0A0AB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  const [validation, setValidation] = useState({
    title: true,
    currency: true,
  });
  const [accountTitle, setAccountTitle] = useState("");
  const [accountCurrency, setAccountCurrency] = useState(null);
  const [accountType, setAccountType] = useState("Real");
  async function CreateAccount() {
    setValidation({
      title: true,
      currency: true,
    });
    if (!accountTitle.length) {
      setValidation((prev) => ({
        ...prev,
        title: false,
      }));
    }
    if (!accountCurrency) {
      setValidation((prev) => ({
        ...prev,
        currency: false,
      }));
    }
    if (!accountTitle.length || !accountCurrency) {
      return;
    }
    axios
      .post(
        process.env.REACT_APP_test_url + `/api/account/create`,
        {
          title: accountTitle,
          type: accountType,
          currency: accountCurrency.label,
          user_id: secureLocalStorage.getItem("user")?.Id,
        },
        {
          headers: {
            Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toggleDrawer(false)();
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    setValidation({ title: true, currency: true });
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor={window.innerWidth < 600 ? "bottom" : "right"}
      onClose={toggleDrawer(false)}
      PaperProps={{
        className: styles.paper,
      }}
    >
      <div className={styles.account_drawer}>
        <div className={styles.top_drawer}>
          <div className={styles.drawer_header}>
            <div>
              <span className={styles.icon}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <ellipse
                    cx="8.00016"
                    cy="3.99967"
                    rx="2.66667"
                    ry="2.66667"
                    stroke="#FCFCFC"
                  />
                  <ellipse
                    cx="8.00016"
                    cy="11.3337"
                    rx="4.66667"
                    ry="2.66667"
                    stroke="#FCFCFC"
                  />
                </svg>
              </span>
              {t("New account")}
            </div>
            <button
              onClick={toggleDrawer(false)}
              className={styles.closeButton}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12 4.00003L4 12M3.99997 4L11.9999 12"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          <div className={styles.drawer_body}>
            <div className={styles.account_type}>
              <div
                className={`${styles.type} ${
                  accountType === "Real" ? styles.active : ""
                }`}
                onClick={() => setAccountType("Real")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={"2.4vh"}
                  height={"2.4vh"}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx={12} cy={12} r={10} stroke="#FCFCFC" />
                  <path
                    d="M12 17V17.5V18"
                    stroke="#FCFCFC"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 6V6.5V7"
                    stroke="#FCFCFC"
                    strokeLinecap="round"
                  />
                  <path
                    d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5"
                    stroke="#FCFCFC"
                    strokeLinecap="round"
                  />
                </svg>
                {t("Real")}
              </div>
              <div
                className={`${styles.type} ${
                  accountType === "Fake" ? styles.active : ""
                }`}
                onClick={() => setAccountType("Fake")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={"2.4vh"}
                  height={"2.4vh"}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.165 4.77922L10.6669 5.13443C11.0567 5.41029 11.5225 5.55844 12 5.55844C12.4776 5.55844 12.9434 5.41029 13.3332 5.13441L13.8351 4.77922C14.5514 4.27225 15.4074 4 16.2849 4H16.8974C17.3016 4 17.7099 4.02549 18.0908 4.16059C20.4735 5.00566 22.1125 8.09503 21.994 15.1026C21.9701 16.5145 21.6397 18.075 20.3658 18.6842C19.9688 18.8741 19.5033 19 18.9733 19C18.3373 19 17.8322 18.8187 17.4424 18.5632C16.5285 17.9642 15.8588 16.9639 14.8888 16.4609C14.3048 16.1581 13.6566 16 12.9989 16H11.0011C10.3434 16 9.69519 16.1581 9.11125 16.4609C8.14122 16.9639 7.47153 17.9642 6.55763 18.5632C6.1678 18.8187 5.66273 19 5.02671 19C4.49667 19 4.03121 18.8741 3.63423 18.6842C2.3603 18.075 2.02992 16.5145 2.00604 15.1026C1.88749 8.09504 3.52645 5.00566 5.90915 4.16059C6.29009 4.02549 6.69838 4 7.10257 4H7.71504C8.59264 4 9.44862 4.27225 10.165 4.77922Z"
                    stroke="#FCFCFC"
                    strokeLinecap="round"
                  />
                  <path
                    d="M7.5 9V12M6 10.5L9 10.5"
                    stroke="#FCFCFC"
                    strokeLinecap="round"
                  />
                  <path
                    className={styles.path_fill}
                    d="M19 10.25C19 10.6642 18.6642 11 18.25 11C17.8358 11 17.5 10.6642 17.5 10.25C17.5 9.83579 17.8358 9.5 18.25 9.5C18.6642 9.5 19 9.83579 19 10.25Z"
                    fill="#1C274C"
                  />
                  <path
                    className={styles.path_fill}
                    d="M16 10.25C16 10.6642 15.6642 11 15.25 11C14.8358 11 14.5 10.6642 14.5 10.25C14.5 9.83579 14.8358 9.5 15.25 9.5C15.6642 9.5 16 9.83579 16 10.25Z"
                    fill="#1C274C"
                  />
                  <path
                    className={styles.path_fill}
                    d="M16.75 8C17.1642 8 17.5 8.33579 17.5 8.75C17.5 9.16421 17.1642 9.5 16.75 9.5C16.3358 9.5 16 9.16421 16 8.75C16 8.33579 16.3358 8 16.75 8Z"
                    fill="#1C274C"
                  />
                  <path
                    className={styles.path_fill}
                    d="M16.75 11C17.1642 11 17.5 11.3358 17.5 11.75C17.5 12.1642 17.1642 12.5 16.75 12.5C16.3358 12.5 16 12.1642 16 11.75C16 11.3358 16.3358 11 16.75 11Z"
                    fill="#1C274C"
                  />
                  <path
                    className={styles.path_fill}
                    d="M18.5 10.25C18.5 10.3881 18.3881 10.5 18.25 10.5C18.1119 10.5 18 10.3881 18 10.25C18 10.1119 18.1119 10 18.25 10C18.3881 10 18.5 10.1119 18.5 10.25ZM15.5 10.25C15.5 10.3881 15.3881 10.5 15.25 10.5C15.1119 10.5 15 10.3881 15 10.25C15 10.1119 15.1119 10 15.25 10C15.3881 10 15.5 10.1119 15.5 10.25ZM16.75 8.5C16.8881 8.5 17 8.61193 17 8.75C17 8.88807 16.8881 9 16.75 9C16.6119 9 16.5 8.88807 16.5 8.75C16.5 8.61193 16.6119 8.5 16.75 8.5ZM16.75 11.5C16.8881 11.5 17 11.6119 17 11.75C17 11.8881 16.8881 12 16.75 12C16.6119 12 16.5 11.8881 16.5 11.75C16.5 11.6119 16.6119 11.5 16.75 11.5Z"
                    stroke="#FCFCFC"
                  />
                </svg>
                {t("Demo")}
              </div>
            </div>
            <div className={styles.label_input}>
              <label htmlFor="name">{t("Name account")}</label>
              <input
                type="text"
                id="name"
                placeholder={t("Name account")}
                onChange={(e) => setAccountTitle(e.target.value)}
                style={!validation.title ? { borderColor: "red" } : {}}
              />
            </div>
            <div className={styles.label_select}>
              <label>{t("Currency")}</label>
              <div
                className={styles.select}
                style={
                  !validation.title
                    ? { border: "1px solid red", borderRadius: "8px" }
                    : {}
                }
              >
                <Select
                  classNamePrefix="custom_select"
                  className={`${styles.select_select} custom-select-container`}
                  placeholder={t("Currency")}
                  menuPlacement="auto"
                  options={[
                    { value: 1, label: "USD" },
                    { value: 2, label: "EUR" },
                  ]}
                  styles={colourStylesCurrency(theme)}
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option: CustomOption,
                  }}
                  value={accountCurrency}
                  onChange={(selectedOption) =>
                    setAccountCurrency(selectedOption)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.drawer_footer}>
          <div onClick={toggleDrawer(false)}>{t("Cancel")}</div>
          <button
            onClick={() => {
              toggleDrawer(false)();
              CreateAccount();
            }}
          >
            {t("Open an account")}
          </button>
        </div>
      </div>
    </Drawer>
  );
}
