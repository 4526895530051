import { useRef, useState, useEffect } from "react";
import styles from "./HomePage.module.scss";
import { useTranslation } from "react-i18next";
import Icon1 from "../../imgs/icon1.svg";
import Icon2 from "../../imgs/icon2.svg";
import Icon3 from "../../imgs/icon3.svg";
import Icon5 from "../../imgs/icon5.svg";
import Icon6 from "../../imgs/icon6.svg";
import Icon7 from "../../imgs/icon7.svg";
import Icon8 from "../../imgs/icon8.svg";
import Icon9 from "../../imgs/icon9.jpg";
import Arrow from "../../imgs/arrow.svg";
import Arrow1 from "../../imgs/arrow1.svg";
import Arrow2 from "../../imgs/arrow2.svg";
import Gradient from "../../imgs/gradient.svg";
import Gradient2 from "../../imgs/gradient2.svg";
import Slider from "../../components/Slider.jsx";
import { slides } from "../../components/Slides.jsx";
import Header from '../../components/Header.jsx';
import Footer from '../../components/Footer.jsx';
import Video1 from '../../imgs/video1.mp4';
import Video2 from '../../imgs/video2.mp4';
import { Link } from "react-router-dom";

export default function HomePage() {
  const { t } = useTranslation();
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSlideChange = (index) => {
    setCurrentSlide(index + 1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.header}>
      <img className={styles.gradient_top} src={Gradient} alt="gradient" />
        <video
          autoPlay
          loop
          muted
          playsInline
          className={styles.backgroundVideo}
        >
          <source
            src={Video1}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className={styles.info}>
          <p>{t("Unlock Your Assets Terminal")}</p>
          <h1>{t("Your Path to Financial Success")}</h1>
          <div className={styles.block}>
            <span>
              {t(
                "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
              )}
            </span>
            <div>
              <Link to='/login'>{t("Login")}</Link>
              <Link to='/register'>{t("Registration")}</Link>
            </div>
          </div>
        </div>
        <img className={styles.gradient_bottom} src={Gradient} alt="gradient" />
      </div>

      <div className={styles.section1}>
        <h2>{t("Trade with a professional")}</h2>
        <div className={styles.blocks}>
          <div className={styles.block}>
            <p>{t("Profitability")}</p>
            <span>
              {t(
                "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading."
              )}
              <br />
              <br />
              {t(
                "And a wide range of payment systems helps to perform financial operations quickly and comfortably."
              )}
            </span>

            <div className={styles.image_container}>
              <img src={Icon1} alt="icon" />
            </div>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
          <div className={styles.block}>
            <p>{t("Technology")}</p>
            <span>
              {t(
                "The company's modern equipment helps traders to trade at the high level accepted in the Forex industry."
              )}
              <br />
              <br />
              {t(
                "Reliable trading platforms, their mobile versions and fast order execution speed increase profitable opportunities."
              )}
            </span>
            <div className={styles.image_container}>
              <img src={Icon2} alt="icon" />
            </div>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
          <div className={styles.block}>
            <p>{t("Services")}</p>
            <span>
              {t(
                "Since 2008, BoerseLink has been providing the best services on the market."
              )}
              <br />
              <br />
              {t(
                "Modern trading platforms, personal support, training and a wide range of profitable trading assets."
              )}
            </span>
            <div className={styles.image_container}>
              <img src={Icon3} alt="icon" />
            </div>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
        </div>
        <div>
          <h2 className={styles.h2}>{t("Your Data. Your Business")}</h2>
          <span className={styles.footer}>
            {t(
              "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading."
            )}
          </span>
        </div>
        
      </div>

      <div className={styles.section2}>
      <div className={styles.videoContainer}>
      <iframe
        className={styles.video}
        // height={'100px'}
        src="https://www.youtube.com/embed/a5XfQWUUZM8?si=IZ8utBQdHik2ESct"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
</div>
        <h2>{t("Advantages of Online Platform")}</h2>
        <img className={styles.background} src={Gradient2} alt="gradient" />
        <div className={styles.blocks}>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon5} alt="icon" />
            </div>
            <p>{t("Wide range of instruments")}</p>
            <span>
              {t(
                "Over 170 high-yielding assets, including the most popular stocks and indices, precious metals, energy and cryptocurrencies."
              )}
            </span>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon6} alt="icon" />
            </div>
            <p>{t("Timely risk management")}</p>
            <span>
              {t(
                "Capture profits and limit potential losses in time by setting Stop Loss and Take Profit right on the chart."
              )}
            </span>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon7} alt="icon" />
            </div>
            <p>{t("All operations are supervised")}</p>
            <span>
              {t(
                "The ability to manage open and closed trades, work with limit orders, as well as view the history of all trades for each selected instrument."
              )}
            </span>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
          <div className={styles.block}>
            <div className={styles.image_container}>
              <img src={Icon8} alt="icon" />
            </div>
            <p>{t("Best Trading Solutions")}</p>
            <span>
              {t(
                "The Autochartist technical advisor built into the platform opens up even more profitable opportunities."
              )}
            </span>
            {/* <div className={styles.link}>
              <span>{t("Read more")}</span>
              <img src={Arrow} alt="arrow" />
            </div> */}
          </div>
        </div>
      </div>

      <div className={styles.section3}>
        <img className={styles.background} src={Gradient2} alt="gradient" />
        <h2>{t("Always on the side of the trader")}</h2>
        <div className={styles.blocks}>
          <div>
            <p>14</p>
            <span>{t("Years")}</span>
          </div>
          <div>
            <p>4</p>
            <span>{t("Platforms")}</span>
          </div>
          <div>
            <p>200</p>
            <span>{t("Instruments")}</span>
          </div>
          <div>
            <p>30</p>
            <span>{t("Signals")}</span>
          </div>
          <div>
            <p>32k</p>
            <span>{t("Clients")}</span>
          </div>
          <div>
            <p>14</p>
            <span>{t("Years")}</span>
          </div>
        </div>
        <div className={styles.footer}>
          <h2 className={styles.title}>{t("Trusted by Global Companies")}</h2>
          <div className={styles.buttons}>
            {windowWidth > 1300 ? (
              <>
                <span>
                  {currentSlide} / {slides.length}
                </span>
                <button onClick={() => sliderRef.current?.slidePrev()}>
                  <img src={Arrow1} alt="arrow" />
                </button>
                <button onClick={() => sliderRef.current?.slideNext()}>
                  <img src={Arrow2} alt="arrow" />
                </button>
              </>
            ) : (
              <>
                <button onClick={() => sliderRef.current?.slidePrev()}>
                  <img src={Arrow1} alt="arrow" />
                </button>
                <span>
                  {currentSlide} / {slides.length}
                </span>
                <button onClick={() => sliderRef.current?.slideNext()}>
                  <img src={Arrow2} alt="arrow" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.section4}>
        <div className={styles.slider}>
          <Slider ref={sliderRef} onSlideChange={handleSlideChange} />
        </div>
        {windowWidth > 600 ? (
          <>
            <div className={styles.left_side}>
              <h2>{t("Your Path to Financial Success")}</h2>
              <span>
                {t(
                  "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
                )}
              </span>
              {/* <div className={styles.buttons}>
                <button>{t("Request a demo")}</button>
                <button>{t("Contact us")}</button>
              </div> */}
            </div>
            <div className={styles.right_side}>
              <img src={Icon9} alt="icon" />
              <img
                className={styles.background}
                src={Gradient2}
                alt="gradient"
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.right_side}>
              <img src={Icon9} alt="icon" />
              <img
                className={styles.background}
                src={Gradient2}
                alt="gradient"
              />
            </div>
            <div className={styles.left_side}>
              <h2>{t("Your Path to Financial Success")}</h2>
              <span>
                {t(
                  "Low minimum deposits, low spreads and additional bonuses when depositing create profitable conditions for trading. And a wide range of payment systems helps to perform financial operations quickly and comfortably."
                )}
              </span>
              <div className={styles.buttons}>
                <button>{t("Request a demo")}</button>
                <button>{t("Contact us")}</button>
              </div>
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}
