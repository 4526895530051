import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Header.module.scss";
import { useTranslation } from "react-i18next";
import Arrow from "../imgs/arrow.svg";
import Logo from "../imgs/logo.png";
import LogoWithoutText from "../imgs/logo-without-text.png";
import { Drawer } from "@mui/material";
import { setToolsDrawer } from "../redux/slices/rootSlice.ts";
import { useAppDispatch, useAppSelector } from "../redux/store.ts";
import Menu from "../imgs/menu.svg";
import Cross from "../imgs/cross.svg";

export default function Header() {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useAppDispatch();
  const toolsDrawer = useAppSelector((state) => state.root.toolsDrawer);
  const [aboutUsDrawer, setAboutUsDrawer] = useState(false);
  const [menuDrawer, setMenuDrawer] = useState(false);

  const handleSetToolsDrawer = () => {
    dispatch(setToolsDrawer(!toolsDrawer));
  };

  const handleSetAboutUsDrawer = () => {
    setAboutUsDrawer(!aboutUsDrawer);
  };

  const handleSetMenuDrawer = () => {
    setMenuDrawer(!menuDrawer);
  };

  useEffect(() => {
    if (toolsDrawer || aboutUsDrawer) {
      setMenuDrawer(false);
    }
  }, [toolsDrawer, aboutUsDrawer]);

  useEffect(() => {
    if (toolsDrawer || aboutUsDrawer || menuDrawer) {
      document.body.style.position = "fixed";
    } else {
      document.body.style.position = "";
    }
  }, [toolsDrawer, aboutUsDrawer, menuDrawer]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeAllDrawers = () => {
    setAboutUsDrawer(false);
    setMenuDrawer(false);
    dispatch(setToolsDrawer(false));
  };

  return (
    <div className={styles.header}>
      {(toolsDrawer || aboutUsDrawer || menuDrawer) && (
        <div className={styles.overlay} onClick={closeAllDrawers}></div>
      )}

      <div className={styles.left_side}>
        <Link to="/" className={styles.logo_container}>
          <img src={LogoWithoutText} alt="logo" width='40px' height='40px' />
        <span>BOERSELINK</span>
        </Link>
        {windowWidth > 1100 && (
          <>
            <Link to="/accounts" className={styles.link}>
              {t("Account types")}
            </Link>
            <div onClick={handleSetToolsDrawer} className={styles.link}>
              {t("Tools")} <img src={Arrow} alt="arrow" />
            </div>
            <Link to="/bonuses" className={styles.link}>
              {t("Bonuses")}
            </Link>
            <div onClick={handleSetAboutUsDrawer} className={styles.link}>
              {t("About us")} <img src={Arrow} alt="arrow" />
            </div>
          </>
        )}
      </div>
      {windowWidth > 1100 ? (
        <div className={styles.right_side}>
          <div className={styles.language_button}></div>
          <Link to="/login" className={styles.login_button}>
            {t("Login")}
          </Link>
          <Link to="/register" className={styles.register_button}>
            {t("Register")}
          </Link>
        </div>
      ) : toolsDrawer || aboutUsDrawer || menuDrawer ? (
        <div onClick={handleSetMenuDrawer} className={styles.right_side}>
          <img src={Cross} alt="icon" />
        </div>
      ) : (
        <div onClick={handleSetMenuDrawer} className={styles.right_side}>
          <img src={Menu} alt="icon" />
        </div>
      )}

      <Drawer
        sx={{
          "& .MuiBackdrop-root": {
            background: "none !important",
          },
        }}
        anchor="top"
        open={toolsDrawer}
        onClose={handleSetToolsDrawer}
        PaperProps={{
          style: {
            width: "100vw",
            marginTop: windowWidth > 1100 ? "68px" : "92px",
          },
        }}
      >
        <div className={styles.drawer}>
          <div className={styles.block}>
            <ul>
              {t("Heat-map")}
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/forexHeatMap"
              >
                {t("Forex Heat-map")}
              </Link>
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/stockHeatMap"
              >
                {t("Heat-map of the stock")}
              </Link>
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/etfHeatMap"
              >
                {t("ETF Heat-map")}
              </Link>
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/CryptoHeatMap"
              >
                {t("Crypto Coin Heat-map")}
              </Link>
            </ul>
          </div>
          <div className={styles.block}>
            <ul>
              {t("Analysis")}
              {/* <Link onClick={handleSetToolsDrawer} className={styles.li} to='/tools'>{t("Technical Analysis")}</Link> */}
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/cryptocurrencyMarket"
              >
                {t("Cryptocurrency Market")}
              </Link>
            </ul>
          </div>
          <div className={styles.block}>
            <ul>
              {t("News")}
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/economicCalendar"
              >
                {t("Economic Calendar")}
              </Link>
              <Link
                onClick={handleSetToolsDrawer}
                className={styles.li}
                to="/tools/topStories"
              >
                {t("Top Stories")}
              </Link>
            </ul>
          </div>
          {/* <div className={styles.block}>
            <ul className={styles.contacts}>
              {t("Contact Us")}
              <li>{t("Address: 123 Main Street, Suite 100,")} <br /> {t("New York, NY 10001")}</li>
              <br />
              <li>{t("Phone: +1 (555) 123-4567")} <br /> {t("Email: info@example.com")}</li>
            </ul>
          </div> */}
        </div>
      </Drawer>
      <Drawer
        sx={{
          "& .MuiBackdrop-root": {
            background: "none !important",
          },
        }}
        anchor="top"
        open={aboutUsDrawer}
        onClose={handleSetAboutUsDrawer}
        PaperProps={{
          style: {
            width: "100vw",
            marginTop: windowWidth > 1100 ? "68px" : "92px",
          },
        }}
      >
        <div className={styles.drawer}>
          <div className={styles.block}>
            <ul>
              {t("Partners")}
              <Link className={styles.li} to="/affiliate">
                {t("Affiliate program")}
              </Link>
            </ul>
          </div>
          <div className={styles.block}>
            <ul>
              {t("Company")}
              <Link className={styles.li} to="/about">
                {t("About company")}
              </Link>
              <Link className={styles.li} to="/privacy">
                {t("Privacy policy")}
              </Link>
            </ul>
          </div>
        </div>
      </Drawer>
      <Drawer
        sx={{
          "& .MuiBackdrop-root": {
            background: "none !important",
          },
        }}
        PaperProps={{
          style: {
            width: "100vw",
            marginTop: windowWidth > 1100 ? "68px" : "92px",
          },
        }}
        anchor="top"
        open={menuDrawer}
        onClose={handleSetMenuDrawer}
      >
        <div className={styles.menu_drawer}>
          <span className={styles.title}>{t("Menu")}</span>
          <div className={styles.list}>
            <Link to="/accounts" className={styles.list_item}>
              <div>
                <span>01</span>
                <p>{t("Account types")}</p>
              </div>
            </Link>
            <div onClick={handleSetToolsDrawer} className={styles.list_item}>
              <div>
                <span>02</span>
                <p>{t("Tools")}</p>
              </div>
              <div>
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
            <Link to="/bonuses" className={styles.list_item}>
              <div>
                <span>03</span>
                <p>{t("Bonuses")}</p>
              </div>
            </Link>
            <div onClick={handleSetAboutUsDrawer} className={styles.list_item}>
              <div>
                <span>04</span>
                <p>{t("About us")}</p>
              </div>
              <div>
                <img src={Arrow} alt="arrow" />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Link to='/login'>{t("Login")}</Link>
            <Link to='/register'>{t("Register")}</Link>
          </div>
          <div className={styles.language_button}></div>
        </div>
      </Drawer>
    </div>
  );
}
