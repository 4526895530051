import React from "react";
import styles from "./PrivacyPolicy.module.scss";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <Header />
      <div lang="de" className={styles.header}>
        <h1 lang="de">{t("Privacy policy")}</h1>
        <p>
          {t(
            "Absolutely new technology. Our artificial intelligence software analyzes the market and opens trading positions faster and more efficiently than 88% of global traders."
          )}
        </p>
      </div>
      <div className={styles.info}>
        <div className={styles.info__container}>
          <h1>{t("Priority of the Company's work")}</h1>
          <p>
            {t(
              "BOERSELINK — is the trust of the Client, as well as the protection and security of their personal data. The Company closely monitors all collected, processed, and issued information through fully protected security systems on all of the Company's electronic media."
            )}
          </p>
          <p>
            {t(
              "The Client's personal information will not be transferred or sold to anyone upon request without the Client's consent and written proof thereof. The Client's data will be used only in accordance with the Service Agreement. The same rules apply to the Company's employees — their personal data is protected in accordance with current laws. Company employees also have the full right not to use their personal data in their work."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("Personal data received from the Client may include")}</h1>
          <p>
            {t(
              "Personal information provided by the Client in applications, questionnaires, and account opening forms: their full name; permanent residence address, citizenship, date of birth, passport details, email address, and mobile phone number;"
            )}
          </p>
          <p>
            {t(
              "Documents provided by the Client as proof of money transfer: transfer order, bank statements, copies of credit cards, etc."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("Use of Personal Information")}</h1>
          <ul className={styles.info__container_list}>
            <p>{t("The Company may use Clients' personal data:")}</p>
            <li>{t("As identification;")}</li>
            <li>{t("For processing Client transactions;")}</li>
            <li>
              {t(
                "To inform the Client about the expansion of the range of products and services provided by the Company;"
              )}
            </li>
            <li>{t("To provide other services.")}</li>
          </ul>
        </div>
        <div className={styles.info__container}>
          <h1>{t("Third Parties")}</h1>
          <p>
            {t(
              "The Company has the right to provide the Client's personal data to third parties, namely affiliated companies, banks, auditors, agents of the Company, including payment agents and other persons (hereinafter referred to as \"Third Parties\") solely for the purpose of fulfilling the terms specified in the Service Agreement. The Company guarantees that Third Parties will comply with the terms of this Notice and will take appropriate measures to maintain the confidentiality of Clients' personal data."
            )}
          </p>

          <p>
            {t(
              "The Company has the right to provide confidential information about Clients' personal data to Third Parties (excluding trusted persons) in the case of an official request issued by an authorized body."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>{t("Right to Lodge a Complaint")}</h1>
          <p>
            {t(
              "The Client has the right to lodge a complaint with regulatory authorities if they believe that their rights under this Notice have been violated by the Company."
            )}
          </p>
        </div>
        <div className={styles.info__container}>
          <h1>
            {t(
              "According to: General Data Protection Regulation (GDPR) Consent to Use Personal Data"
            )}
          </h1>
          <p>
            {t(
              "By registering an account with the Company and/or using any services provided by the Company, the Client consents to the use and processing of their personal data in accordance with the terms of the Service Agreement and the Data Privacy Notice for the duration of this Consent."
            )}
          </p>
          <p>
            {t(
              "This Consent remains in effect for the entire term of the Service Agreement between the Company and the Client and for one year after the termination of the Service Agreement or until the Client revokes the Consent (as described earlier)."
            )}
          </p>
          <p>
            {t(
              "Please indicate your consent/disagreement to the above-mentioned processing of your personal data by checking/unchecking the box below: ✔I Agree;"
            )}
          </p>
          <p>
            {t(
              "The Client agrees that cookies may be used on certain pages of the Company's website to provide all Clients with faster and more convenient access. If the Client objects to the use of cookies, most modern web browsers provide the option to refuse cookies, ensuring full access to the Company's website."
            )}
          </p>
          <p>
            {t(
              "Here at BOERSELINK, we take your privacy seriously and will use your personal information to administer your account and provide the products and services you have requested from us."
            )}
          </p>
          <p>
            {t(
              "However, from time to time, we would like to be able to contact you to provide detailed information about other services/contests that we offer. If you consent to being contacted for this purpose, please indicate your preferred method of contact: ✔Publication ✔Email ✔Phone ✔Text Message ✔Automated Call;"
            )}
          </p>
          <p>
            {t(
              "We would also like to pass your data to another clearly defined category of companies that are our partners so they can contact you by mail with detailed information about offers/services/contests they provide. If you consent to the transfer of your data for this purpose, please check the box to confirm: ✔I Agree;"
            )}
          </p>
          <p>
            {t(
              "The Client should be informed that they can withdraw this Consent at any time by sending a detailed cancellation notice to support@tradeallcrypto.email. In this case, the Consent is considered withdrawn immediately after the Company receives the notice from the Client. The Client understands and agrees to this right to cancel the Consent: ✔I Agree;"
            )}
          </p>
          <p>
            {t(
              "The Client is duly informed of their right to lodge a complaint with regulatory authorities if they believe that their rights under this Consent have been violated by the Company: ✔I Have Been Informed"
            )}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
