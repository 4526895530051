import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../styles/MobileOrderDrawer.module.scss";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { colourStylesCurrency } from "./SelectCurrencyStyles";
import altIcon from "../imgs/altIcon.svg";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";

export default function MobileOrderDrawer({
  currentRecords,
  open,
  toggleDrawer,
  theme,
  activeOrder,
  eurToUsd,
}) {
  const { t } = useTranslation();
  const [accountType, setAccountType] = useState("Real");
  const [marketPrice, setMarketPrice] = useState("");

  useEffect(() => {
    if (activeOrder && activeOrder.MarketPrice) {
      setMarketPrice(
        currentRecords.find((el) => el.Id === activeOrder.Id).MarketPrice
      );
    }
  }, [currentRecords, activeOrder]);
  const CustomOption = (props) => {
    const { innerProps, innerRef } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          props.isSelected
            ? styles.custom_option_selected
            : styles.custom_option
        }
      >
        <div
          className={styles.option}
          style={{
            color: props.data.color,
            backgroundColor: props.data.background,
            width: "auto",
          }}
        >
          {props.data.label}
        </div>
      </div>
    );
  };

  const handleImgError = (e) => {
    e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
  };
  const DropdownIndicator = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.single_value_container}>
        <div className={styles.custom_icon}>
          <svg
            width={"1.7vh"}
            height={"1.6vh"}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5003 6L8.83366 10L4.16699 6"
              stroke="#A0A0AB"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        {children}
      </div>
    </components.DropdownIndicator>
  );
  // console.log(activeOrder)
  return (
    <Drawer
      open={open}
      anchor={"bottom"}
      onClose={toggleDrawer(false)}
      PaperProps={{
        className: styles.paper,
      }}
    >
      <div className={styles.account_drawer}>
        <div className={styles.top_drawer}>
          <div className={styles.drawer_header}>
            <div>
              <img
                src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
                  activeOrder?.Subtitle
                    ? activeOrder?.Subtitle?.slice(1).slice(0, -1).toLowerCase()
                    : activeOrder?.SubTitle?.slice(1).slice(0, -1).toLowerCase()
                }.svg`}
                alt="icon"
                onError={handleImgError}
              />
              {activeOrder?.Subtitle
                ? activeOrder?.Subtitle?.slice(1).slice(0, -1).slice(0, 4)
                : activeOrder?.SubTitle?.slice(1).slice(0, -1).slice(0, 4)}
              {activeOrder?.Status && (
                <span
                  className={`${styles.type} ${
                    activeOrder?.Status?.includes("BUY")
                      ? styles.buy
                      : activeOrder?.Status?.includes("SELL")
                      ? styles.sell
                      : styles.simulate
                  }`}
                >
                  {activeOrder?.Status}
                </span>
              )}
              {activeOrder?.OrderType && (
                <span
                  className={`${styles.type} ${
                    activeOrder?.OrderType?.includes("BUY")
                      ? styles.buy
                      : activeOrder?.OrderType?.includes("SELL")
                      ? styles.sell
                      : styles.simulate
                  }`}
                >
                  {activeOrder?.OrderType}
                </span>
              )}
            </div>

            <button onClick={toggleDrawer(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M12 4.00003L4 12M3.99997 4L11.9999 12"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          <div className={styles.drawer_body}>
            <div className={styles.values}>
              <label>{t("Position")}</label>
              <div className={styles.value}>{activeOrder?.Id}</div>
            </div>
            <div className={styles.values}>
              <label>{t("Account")}</label>
              <div className={styles.value}>Brooklyn Simmons</div>
            </div>
            <div className={`${styles.values} ${styles.tool}`}>
              <label>{t("Tool")}</label>
              <div className={styles.value}>
                <img
                  src={`https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${
                    activeOrder?.Subtitle
                      ? activeOrder?.Subtitle?.slice(1)
                          .slice(0, -1)
                          .toLowerCase()
                      : activeOrder?.SubTitle?.slice(1)
                          .slice(0, -1)
                          .toLowerCase()
                  }.svg`}
                  alt="icon"
                  onError={handleImgError}
                />
                {activeOrder?.Subtitle
                  ? activeOrder?.Subtitle?.slice(1).slice(0, -1).slice(0, 4)
                  : activeOrder?.SubTitle?.slice(1).slice(0, -1).slice(0, 4)}
              </div>
            </div>
            <div className={styles.values}>
              <label>{t("Margin")}</label>
              <div className={styles.value}>
                {secureLocalStorage.getItem("user")?.Currency == "EUR"
                  ? "€"
                  : "$"}
                {activeOrder?.Price}
              </div>
            </div>
            <div className={styles.values}>
              <label>{t("Entry price")}</label>
              <div className={styles.value}>
                {secureLocalStorage.getItem("user")?.Currency == "EUR"
                  ? "€"
                  : "$"}
                {activeOrder?.StartPrice}
              </div>
            </div>
            <div className={styles.values}>
              <label>{t("Leverage")}</label>
              <div
                className={styles.value}
                style={{ color: "#E9770E", textTransform: "uppercase" }}
              >
                x{activeOrder?.Leverage}
              </div>
            </div>
            <div className={styles.values}>
              <label>{t("Liquidation")}</label>
              <div className={styles.value} style={{ color: "#F63D68" }}>
                {" "}
                {activeOrder?.Status === "BUY"
                  ? activeOrder?.Leverage !== 1
                    ? `${(
                        +(
                          activeOrder?.StartPrice *
                          (secureLocalStorage.getItem("user")?.Currency == "EUR"
                            ? eurToUsd
                            : 1)
                        ) -
                        (
                          +activeOrder?.StartPrice *
                          (secureLocalStorage.getItem("user")?.Currency == "EUR"
                            ? eurToUsd
                            : 1)
                        ).toFixed(4) *
                          (100 / (+activeOrder?.Leverage * 100))
                      ).toFixed(4)} ${
                        secureLocalStorage.getItem("user")?.Currency == "EUR"
                          ? "€"
                          : "$"
                      }`
                    : "-- --"
                  : activeOrder?.Leverage !== 1
                  ? `${(
                      +(
                        activeOrder?.StartPrice *
                        (secureLocalStorage.getItem("user")?.Currency == "EUR"
                          ? eurToUsd
                          : 1)
                      ) +
                      (
                        +activeOrder?.StartPrice *
                        (secureLocalStorage.getItem("user")?.Currency == "EUR"
                          ? eurToUsd
                          : 1)
                      ).toFixed(4) *
                        (100 / (+activeOrder?.Leverage * 100))
                    ).toFixed(4)}
  
                                  ${
                                    secureLocalStorage.getItem("user")
                                      ?.Currency == "EUR"
                                      ? "€"
                                      : "$"
                                  }`
                  : "-- --"}
              </div>
            </div>
            {marketPrice ? (
              <div className={styles.values}>
                <label>{t("Market price")}</label>
                <div className={styles.value}>
                  {secureLocalStorage.getItem("user")?.Currency == "EUR"
                    ? "€"
                    : "$"}
                  {marketPrice}
                </div>
              </div>
            ) : (
              <div className={styles.values}>
                <label>{t("Profit")}</label>
                <div
                  className={styles.value}
                  style={{
                    color:
                      parseFloat(activeOrder?.Profit) > 0
                        ? "#00B4A0"
                        : "#F63D68",
                  }}
                >
                  {activeOrder?.Profit > 0 ? "+" : ""}
                  {parseFloat(`${activeOrder?.Profit}`.slice(1)) > 1
                    ? " " +
                      (activeOrder?.Profit < 0
                        ? "- " +
                          parseFloat(`${activeOrder?.Profit}`.slice(1)).toFixed(
                            2
                          )
                        : activeOrder?.Profit?.toFixed(2))
                    : " " +
                      (activeOrder?.Profit < 0
                        ? "- " +
                          parseFloat(`${activeOrder?.Profit}`.slice(1)).toFixed(
                            5
                          )
                        : activeOrder?.Profit?.toFixed(5))}
                  {secureLocalStorage.getItem("user")?.Currency == "EUR"
                    ? " €"
                    : " $"}
                </div>
              </div>
            )}

            <div className={`${styles.values} ${styles.tp_sl}`}>
              <label>{t("TP/SL")}</label>
              <div className={styles.value}>
                <span className={styles.tp}>
                  {activeOrder?.TakeProfit
                    ? activeOrder?.TakeProfit > 1
                      ? activeOrder?.TakeProfit.toFixed(2)
                      : activeOrder?.TakeProfit.toFixed(5)
                    : 0}
                  {secureLocalStorage.getItem("user")?.Currency == "EUR"
                    ? "€"
                    : "$"}
                </span>
                /
                <span className={styles.sl}>
                  {activeOrder?.StopLoss
                    ? activeOrder?.StopLoss > 1
                      ? activeOrder?.StopLoss.toFixed(2)
                      : activeOrder?.StopLoss.toFixed(5)
                    : 0}
                  {secureLocalStorage.getItem("user")?.Currency == "EUR"
                    ? "€"
                    : "$"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.drawer_footer}>
          <div onClick={toggleDrawer(false)}>{t("Close")}</div>
        </div>
      </div>
    </Drawer>
  );
}
