import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Applications.module.scss'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select';
import { colourStylesStatus } from '../../components/SelectStatusStyles';
import { colourStylesCurrency } from '../../components/SelectCurrencyStyles';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import AccountsTable from '../../components/AccountsTable';
import OrdersTable from '../../components/OrdersTable';
import moment from 'moment';
import altIcon from '../../imgs/altIcon.svg'
import MobileOrderDrawer from '../../components/MobileOrderDrawer';
import MobileApplicationDrawer from '../../components/MobileApplicationDrawer';
export default function Applications({ theme, eurToUsd ,setSnackType,setSnackMessage,handleClick,account}) {
    const { t, i18n } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('open');
    const [backgroundTableStyle, setBackgroundTableStyle] = useState({});
    const open = useRef(null);
    const deferred = useRef(null);
    const close = useRef(null);
    const [history, setHistory] = useState([]);
    const [historyB, setHistoryB] = useState([]);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [activeOrder , setActiveOrder] = useState({})
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState({ value: 3, label: t('All'), color: '#A855F7', background: 'rgba(168, 85, 247, 0.12)' });
    const [searchCurrency, setSearchCurrency] = useState(false);
    const toggleDrawer = (newOpen,element) => () => {
        setOpenDrawer(newOpen);
        setActiveOrder(element)
    };
    const handleImgError = (e) => {
        e.target.src = altIcon; // Подставляем альтернативную иконку при ошибке
    };
    const CustomOption = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} className={props.isSelected ? styles.custom_option_selected : styles.custom_option}>
                <div className={styles.option} style={{ color: props.data.color, backgroundColor: props.data.background, width: 'auto' }}>
                    {props.data.label}
                </div>
            </div>)
    }

    const DropdownIndicator = ({ children, ...props }) => (
        <components.DropdownIndicator {...props}>
            <div className={styles.single_value_container}>
                <div className={styles.custom_icon}>
                    <svg width={"1.7vh"} height={"1.6vh"} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5003 6L8.83366 10L4.16699 6" stroke="#A0A0AB" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                {children}
            </div>
        </components.DropdownIndicator>
    );

    const handleTabTableChange = (tab) => {
        setSelectedTab(tab)
    };

    const updateBackgroundPositionTable = () => {
        let targetTab;
        switch (selectedTab) {
            case 'open':
                targetTab = open.current;
                break;
            case 'deferred':
                targetTab = deferred.current;
                break;
            case 'close':
                targetTab = close.current;
                break;
            default:
                targetTab = open.current;
        }

        if (targetTab) {
            setBackgroundTableStyle({
                width: targetTab.offsetWidth,
                height: targetTab.offsetHeight,
                transform: `translateX(${targetTab.offsetLeft - 3}px)`,
                transition: 'background-color 0.3s ease, transform 0.3s ease', // Добавляем анимацию
            });
        }
    };
    useEffect(() => {
        updateBackgroundPositionTable();
    }, [selectedTab]);

    useEffect(() => {
        var timer;
        const fetchData = async () => {

            await axios.get(process.env.REACT_APP_test_url + `/api/applications/${account?.Id}`, {
                headers: {
                    'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                }
            })
                .then((res) => {
                    setHistory(res.data.applications);
                    // setHistoryB(res.data.applications);
                })
                .catch((err) => {
                    console.log(err);
                })
            timer = setInterval(() => {
                axios.get(process.env.REACT_APP_test_url + `/api/applications/${account?.Id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + secureLocalStorage.getItem('token')
                    }
                }).then((result) => {
                    setHistory(result.data.applications)
                    // setHistoryB(result.data.applications)
                }).catch(e => console.log(e))
            }, 120000)
        }
        fetchData()

        return ()=>{
            clearInterval(timer);
        }
    }, [account])

    const filterH = () => {
        if (search?.length || searchType || searchCurrency) {
          return history.filter((val) => {
            const matchesSearch = search?.length
              ? (val?.Status.toString().trim().toLowerCase().includes(search.trim().toLowerCase()) || val?.PlatformId == search || val?.Id == search)
              : true;
      
            const matchesType = searchType
            ? searchType?.value !== 3 
              ? (searchType?.value === 1 ? ["Submitted","Expectation"].includes(val?.Status) : ["Done","Rejected"].includes(val?.Status))
              : true
            :true
      
           
      
            return matchesSearch && matchesType ;
          });
        } else {
          return history;
        }
    };

    useEffect(()=>{
        setHistoryB(filterH())
    },[search,searchType,history])
    
    const columns = useMemo(
        () => [
            {
                accessorKey: 'Date',
                header: t('Date'),
                size: 60,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Date')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span>
                            {moment(row.original.Date).format('YYYY-MM-DD')}
                            <span style={{ color: theme === 'dark' ? "#51525C" : "#9CA3AF" }}> {moment(row.original.Date).format('HH:mm')}</span>
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Id',
                header: t('OrderId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('OrderId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 60,
            },
            {
                accessorKey: 'PlatformId',
                header: t('AccountId'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('AccountId')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 60,
            },
            {
                accessorKey: 'Currency',
                header: t('Currency'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Currency')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
                Cell: ({ renderedCellValue, row }) => {
                    const el = secureLocalStorage.getItem('accounts').find((el)=>el.Id == row.original.PlatformId);
                    return (
                        <span className={styles.currency}>
                            {el?.AccountCurrency === 'USD'
                                ?
                                <svg xmlns="http://www.w3.org/2000/svg" width={'2.4vh'} height={'2.4vh'} viewBox="0 0 24 24" fill="none">
                                    <g clipPath="url(#clip0_270_9485)">
                                        <path d="M11.818 23.2806C18.0769 23.2806 23.1122 18.2454 23.1122 11.9865C23.1122 5.72764 18.0769 0.692383 11.818 0.692383C5.55918 0.692383 0.523926 5.72764 0.523926 11.9865C0.523926 18.2454 5.55918 23.2806 11.818 23.2806Z" fill="#00B4A0" />
                                        <path d="M14.9239 13.7738C14.9239 12.1268 13.9357 11.5621 11.9593 11.3268C10.5475 11.1386 10.2652 10.7621 10.2652 10.1032C10.2652 9.44432 10.7358 9.0209 11.6769 9.0209C12.524 9.0209 12.9946 9.30325 13.2298 10.0091C13.2769 10.1503 13.4181 10.2444 13.5593 10.2444H14.3121C14.5004 10.2444 14.6416 10.1032 14.6416 9.91506V9.86796C14.4533 8.83263 13.6063 8.03266 12.524 7.93858V6.80916C12.524 6.62089 12.3828 6.47971 12.1475 6.43262H11.4417C11.2534 6.43262 11.1122 6.57379 11.0651 6.80916V7.89148C9.65337 8.07976 8.75934 9.0209 8.75934 10.1974C8.75934 11.7504 9.70046 12.3621 11.6769 12.5974C12.9946 12.8327 13.4181 13.115 13.4181 13.868C13.4181 14.621 12.7592 15.1386 11.8652 15.1386C10.6416 15.1386 10.2181 14.6209 10.0769 13.915C10.0299 13.7268 9.88874 13.6326 9.74756 13.6326H8.9475C8.75934 13.6326 8.61816 13.7738 8.61816 13.9621V14.0092C8.80632 15.1856 9.55929 16.0327 11.1122 16.268V17.3974C11.1122 17.5856 11.2534 17.7268 11.4886 17.7739H12.1945C12.3828 17.7739 12.524 17.6327 12.5711 17.3974V16.268C13.9828 16.0327 14.9239 15.0444 14.9239 13.7738Z" fill="white" />
                                        <path d="M9.419 18.7157C5.74842 17.3981 3.86603 13.304 5.23081 9.68037C5.93669 7.7039 7.48963 6.19806 9.419 5.49217C9.60728 5.39809 9.70136 5.25692 9.70136 5.02155V4.36276C9.70136 4.17449 9.60728 4.03331 9.419 3.98633C9.37191 3.98633 9.27783 3.98633 9.23073 4.03331C4.76018 5.44508 2.31308 10.1981 3.72485 14.6686C4.57191 17.3039 6.59547 19.3275 9.23073 20.1745C9.419 20.2686 9.60728 20.1745 9.65426 19.9863C9.70136 19.9393 9.70136 19.8922 9.70136 19.7981V19.1392C9.70136 18.998 9.56018 18.8099 9.419 18.7157ZM14.4073 4.03331C14.219 3.93923 14.0307 4.03331 13.9837 4.22158C13.9367 4.26868 13.9367 4.31566 13.9367 4.40986V5.06864C13.9367 5.25692 14.0778 5.44508 14.219 5.53927C17.8896 6.85684 19.772 10.951 18.4072 14.5746C17.7013 16.551 16.1484 18.0569 14.219 18.7628C14.0307 18.8568 13.9367 18.998 13.9367 19.2334V19.8922C13.9367 20.0804 14.0307 20.2216 14.219 20.2686C14.2661 20.2686 14.3602 20.2686 14.4073 20.2216C18.8778 18.8099 21.3249 14.0568 19.9132 9.58629C19.0661 6.90394 16.9954 4.88037 14.4073 4.03331Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_270_9485">
                                            <rect width={24} height={24} fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                : 
                                <svg viewBox="0 0 32 32" width={'2.4vh'} height={'2.4vh'} xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fill-rule="evenodd"> <circle cx="16" cy="16" fill="#0f8ff8" r="16"></circle> <path d="M8 19.004L8.81 17h.857a16.279 16.279 0 01-.034-1.03c0-.448.019-.864.056-1.25H8l.81-2.003h1.274C11.27 8.906 13.944 7 18.103 7c1.367 0 2.666.177 3.897.532v2.524a8.92 8.92 0 00-3.683-.776c-2.493 0-4.096 1.146-4.81 3.438h7.423l-.81 2.003h-7.097a6.938 6.938 0 00-.056.995c0 .479.015.907.045 1.285h6.183l-.8 2.003H13.44c.533 1.389 1.183 2.355 1.949 2.9.765.544 1.858.816 3.277.816 1.014 0 2.125-.247 3.334-.741v2.373c-1.149.432-2.515.648-4.1.648-4.167 0-6.803-1.999-7.906-5.996z" fill="#ffffff"></path> </g> </g></svg>
          
                            }
                            {el?.AccountCurrency}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Sum',
                header: t('Amount'),
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Amount')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                size: 80,
                Cell: ({ renderedCellValue, row }) => {
                    const el = secureLocalStorage.getItem('accounts').find((el)=>el.Id == row.original.PlatformId);
                    return (
                        <span>
                            {
                                el?.AccountCurrency == "EUR"
                                    ?
                                    "€"
                                    :
                                    "$"
                            }
                            {(row.original.Sum * (el?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(2)}
                        </span>
                    )
                },
            },
            {
                accessorKey: 'Status',
                header: t('Status'),
                size: 110,
                Header: () => (
                    <div className={styles.header_cell}>
                        <span style={{ fontWeight: '500', lineHeight: '1.6vh' }} className={styles.header_name}>{t('Status')}</span>
                        <div className={styles.sort_icon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M1 4L4 1L7 4" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={"0.8vh"} height={"0.5vh"} viewBox="0 0 8 5" fill="none">
                                <path d="M7 1L4 4L1 1" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                ),
                Cell: ({ renderedCellValue, row }) => {
                    return (
                        <span className={
                            ["Submitted","Expectation"].includes(row.original.Status)
                            ?
                            styles.status_pending
                            :
                                row.original.Status == "Rejected"
                                ?
                                styles.status_denied
                                :
                                styles.status_good
                            }>
                            {row.original.Status?.slice(0, 1) + row.original.Status?.slice(1).toLowerCase()}
                        </span>
                    )
                },
            },
        ],
        [i18n.language, account?.AccountCurrency, historyB],
    );
    return (
        <div className={styles.orders}>
            <div className={styles.orders_title}>
                <svg xmlns="http://www.w3.org/2000/svg" width={"1.6vh"} height={"1.6vh"} viewBox="0 0 16 16" fill="none">
                    <path d="M6.33333 1.33398C5.78105 1.33398 5.33333 1.7817 5.33333 2.33398V3.00065C5.33333 3.55294 5.78105 4.00065 6.33333 4.00065H9.66667C10.219 4.00065 10.6667 3.55294 10.6667 3.00065V2.33398C10.6667 1.7817 10.219 1.33398 9.66667 1.33398H6.33333Z" fill="#FCFCFC" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.33333 2.69173C3.49473 2.73878 2.96532 2.87241 2.58579 3.25195C2 3.83773 2 4.78054 2 6.66616V10.6662C2 12.5518 2 13.4946 2.58579 14.0804C3.17157 14.6662 4.11438 14.6662 6 14.6662H10C11.8856 14.6662 12.8284 14.6662 13.4142 14.0804C14 13.4946 14 12.5518 14 10.6662V6.66616C14 4.78054 14 3.83773 13.4142 3.25195C13.0347 2.87241 12.5053 2.73878 11.6667 2.69173V3.00065C11.6667 4.10522 10.7712 5.00065 9.66667 5.00065H6.33333C5.22876 5.00065 4.33333 4.10522 4.33333 3.00065V2.69173ZM4.66667 9.16732C4.39052 9.16732 4.16667 9.39118 4.16667 9.66732C4.16667 9.94346 4.39052 10.1673 4.66667 10.1673H10C10.2761 10.1673 10.5 9.94346 10.5 9.66732C10.5 9.39118 10.2761 9.16732 10 9.16732H4.66667ZM4.66667 11.5007C4.39052 11.5007 4.16667 11.7245 4.16667 12.0007C4.16667 12.2768 4.39052 12.5007 4.66667 12.5007H8.33333C8.60948 12.5007 8.83333 12.2768 8.83333 12.0007C8.83333 11.7245 8.60948 11.5007 8.33333 11.5007H4.66667Z" fill="#FCFCFC" />
                </svg>
                {t('Applications')}
            </div>
            <div className={styles.orders_table}>
                <div className={styles.table_header}>
                    <div className={styles.right_side}>
                        <div className={styles.search}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'1.6vh'} height={'1.6vh'} viewBox="0 0 16 16" fill="none">
                                <g clipPath="url(#clip0_339_5008)">
                                    <circle cx="7.66659" cy="7.66634" r="6.33333" stroke="#51525C" />
                                    <path d="M13.3333 13.333L14.6666 14.6663" stroke="#51525C" strokeLinecap="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_339_5008">
                                        <rect width={16} height={16} fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <input placeholder={t('Search')} onChange={(e)=>setSearch(e.target.value)} value={search}/>
                        </div>
                        <div className={styles.select}>
                            <Select
                                classNamePrefix="custom_select"
                                className={`${styles.select_select} custom-select-container`}
                                placeholder={'Type'}
                                menuPlacement='auto'
                                options={[
                                    { value: 1, label: t('Executed'), color: '#00B4A0', background: 'rgba(0, 180, 160, 0.08)' },
                                    { value: 2, label: t('New'), color: '#3B82F6', background: 'rgba(59, 130, 246, 0.08)' },
                                    { value: 3, label: t('All'), color: '#A855F7', background: 'rgba(168, 85, 247, 0.12)' },
                                ]}                                
                                styles={colourStylesStatus(theme, 'Sale')}
                                components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: CustomOption }}
                                onChange={setSearchType}
                                value={searchType}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.table}>
                    <OrdersTable
                        data={historyB}
                        columns={columns}
                        theme={theme}
                    />
                </div>

                <div className={styles.mobile_transactions}>
                    <div className={styles.transactions_list}>
                        {
                            historyB.map(el =>
                                <div onClick={toggleDrawer(true,el)}  className={styles.transaction} key={el.Id}>
                                    <div className={styles.type_status}>
                                        <div className={`${styles.left_side}`}>
                                            {secureLocalStorage.getItem('accounts').find((val)=>val.Id == el?.PlatformId)?.AccountCurrency === 'USD'
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width={'3.2vh'} height={'3.2vh'} viewBox="0 0 24 24" fill="none">
                                                    <g clipPath="url(#clip0_270_9485)">
                                                        <path d="M11.818 23.2806C18.0769 23.2806 23.1122 18.2454 23.1122 11.9865C23.1122 5.72764 18.0769 0.692383 11.818 0.692383C5.55918 0.692383 0.523926 5.72764 0.523926 11.9865C0.523926 18.2454 5.55918 23.2806 11.818 23.2806Z" fill="#00B4A0" />
                                                        <path d="M14.9239 13.7738C14.9239 12.1268 13.9357 11.5621 11.9593 11.3268C10.5475 11.1386 10.2652 10.7621 10.2652 10.1032C10.2652 9.44432 10.7358 9.0209 11.6769 9.0209C12.524 9.0209 12.9946 9.30325 13.2298 10.0091C13.2769 10.1503 13.4181 10.2444 13.5593 10.2444H14.3121C14.5004 10.2444 14.6416 10.1032 14.6416 9.91506V9.86796C14.4533 8.83263 13.6063 8.03266 12.524 7.93858V6.80916C12.524 6.62089 12.3828 6.47971 12.1475 6.43262H11.4417C11.2534 6.43262 11.1122 6.57379 11.0651 6.80916V7.89148C9.65337 8.07976 8.75934 9.0209 8.75934 10.1974C8.75934 11.7504 9.70046 12.3621 11.6769 12.5974C12.9946 12.8327 13.4181 13.115 13.4181 13.868C13.4181 14.621 12.7592 15.1386 11.8652 15.1386C10.6416 15.1386 10.2181 14.6209 10.0769 13.915C10.0299 13.7268 9.88874 13.6326 9.74756 13.6326H8.9475C8.75934 13.6326 8.61816 13.7738 8.61816 13.9621V14.0092C8.80632 15.1856 9.55929 16.0327 11.1122 16.268V17.3974C11.1122 17.5856 11.2534 17.7268 11.4886 17.7739H12.1945C12.3828 17.7739 12.524 17.6327 12.5711 17.3974V16.268C13.9828 16.0327 14.9239 15.0444 14.9239 13.7738Z" fill="white" />
                                                        <path d="M9.419 18.7157C5.74842 17.3981 3.86603 13.304 5.23081 9.68037C5.93669 7.7039 7.48963 6.19806 9.419 5.49217C9.60728 5.39809 9.70136 5.25692 9.70136 5.02155V4.36276C9.70136 4.17449 9.60728 4.03331 9.419 3.98633C9.37191 3.98633 9.27783 3.98633 9.23073 4.03331C4.76018 5.44508 2.31308 10.1981 3.72485 14.6686C4.57191 17.3039 6.59547 19.3275 9.23073 20.1745C9.419 20.2686 9.60728 20.1745 9.65426 19.9863C9.70136 19.9393 9.70136 19.8922 9.70136 19.7981V19.1392C9.70136 18.998 9.56018 18.8099 9.419 18.7157ZM14.4073 4.03331C14.219 3.93923 14.0307 4.03331 13.9837 4.22158C13.9367 4.26868 13.9367 4.31566 13.9367 4.40986V5.06864C13.9367 5.25692 14.0778 5.44508 14.219 5.53927C17.8896 6.85684 19.772 10.951 18.4072 14.5746C17.7013 16.551 16.1484 18.0569 14.219 18.7628C14.0307 18.8568 13.9367 18.998 13.9367 19.2334V19.8922C13.9367 20.0804 14.0307 20.2216 14.219 20.2686C14.2661 20.2686 14.3602 20.2686 14.4073 20.2216C18.8778 18.8099 21.3249 14.0568 19.9132 9.58629C19.0661 6.90394 16.9954 4.88037 14.4073 4.03331Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_270_9485">
                                                            <rect width={24} height={24} fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                : 
                                                <svg viewBox="0 0 32 32" width={'3.2vh'} height={'3.2vh'} xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fill-rule="evenodd"> <circle cx="16" cy="16" fill="#0f8ff8" r="16"></circle> <path d="M8 19.004L8.81 17h.857a16.279 16.279 0 01-.034-1.03c0-.448.019-.864.056-1.25H8l.81-2.003h1.274C11.27 8.906 13.944 7 18.103 7c1.367 0 2.666.177 3.897.532v2.524a8.92 8.92 0 00-3.683-.776c-2.493 0-4.096 1.146-4.81 3.438h7.423l-.81 2.003h-7.097a6.938 6.938 0 00-.056.995c0 .479.015.907.045 1.285h6.183l-.8 2.003H13.44c.533 1.389 1.183 2.355 1.949 2.9.765.544 1.858.816 3.277.816 1.014 0 2.125-.247 3.334-.741v2.373c-1.149.432-2.515.648-4.1.648-4.167 0-6.803-1.999-7.906-5.996z" fill="#ffffff"></path> </g> </g></svg>
                                            }
                                            {/* <span className={`${styles.type} ${["Submitted","Expectation"].includes(el?.Status) ? styles.buy : styles.sell}`}>{el.PlatformId}</span> */}
                                        </div>
                                        <div className={styles.right_side}>
                                            <span className={
                                                ["Submitted","Expectation"].includes(el?.Status)
                                                ?
                                                styles.status_pending
                                                :
                                                    el?.Status == "Rejected"
                                                    ?
                                                    styles.status_denied
                                                    :
                                                    styles.status_good
                                                }>
                                                {el.Status?.slice(0, 1) + el.Status?.slice(1).toLowerCase()}
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className={styles.account_amount}>
                                        <div className={styles.account}>
                                            <label htmlFor="">{t('Amount')}</label>
                                            <p style={{color:parseFloat(el.Sum)> 0?'#00B4A0':"#F63D68"}}>
                                                {
                                                    secureLocalStorage.getItem('accounts').find((val)=>val.Id == el?.PlatformId)?.AccountCurrency == "EUR"
                                                        ?
                                                        "€"
                                                        :
                                                        "$"
                                                }
                                                {(el.Sum * (secureLocalStorage.getItem('accounts').find((val)=>val.Id == el?.PlatformId)?.AccountCurrency == "EUR" ? eurToUsd : 1))?.toFixed(2)}
                                            </p>
                                        </div>
                                        <div className={styles.account} style={{ alignItems: 'end' }}>
                                            <label htmlFor="">{t('Date')}</label>
                                            <p>
                                                {moment(el.Date).format("YYYY-MM-DD HH:mm")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <MobileApplicationDrawer account={account}  theme={theme} open={openDrawer} eurToUsd={eurToUsd} toggleDrawer={toggleDrawer} activeOrder={activeOrder} marketPrice={activeOrder?.MarketPrice}/>
        </div>
    )
}