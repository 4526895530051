export const colourStylesCurrency = (theme ) => {return {
    control: (styles) => ({
        ...styles,
        backgroundColor: `${theme=== 'dark'?'#131316':'#F3F4F6'}`,
        minHeight: '3.4vh',
        color: "#fff",
        border: `1px solid ${theme=== 'dark'?'#26272B':'#E5E7EB'}`,
        boxShadow: 'none',
        borderRadius: '8px',
        position: 'relative',
        width: 'auto', // Контейнер адаптируется к содержимому
        display: 'flex',
        alignItems: 'center',
        '.custom_select__value-container': {
            paddingTop: '0.6vh',
            paddingBottom: '0.6vh',
            paddingLeft: '1.2vh',
        },
        '.custom_select__indicators': {
            '.custom_select__indicator': {
                padding:'0',
                paddingRight:'0.6vh',
            }
        }
    }),
    placeholder: (styles) => ({
        ...styles,
        display: 'inline-block',
        width: 'auto',
        fontSize: '1.4vh',
        padding: '0.4vh 0.4vh',
        borderRadius: '8px',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        whiteSpace: 'nowrap',
    }),
    singleValue: (baseStyles, { data }) => ({
        ...baseStyles,
        display: 'flex',
        width: 'auto',
        alignItems:'center',
        justifyContent:'center',
        fontWeight: '500',
        color: `${theme ==='dark'?'#FCFCFC':'#1F2937'}`,
        background: data.background,
        fontSize: '1.4vh',
        padding: '0.4vh 0.4vh',
        borderRadius: '8px',
        whiteSpace: 'nowrap',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    }),
    menuList: (styles) => ({
        ...styles,
        background: theme === 'dark'?'#1D1D20':'#FCFCFD',
        color: '#fff',
        borderRadius: '8px',
        padding: '0.4vh 0.4vh',
        boxShadow:'0px 4px 32px 0px rgba(0, 0, 0, 0.32)',
        zIndex: '1000',
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: theme === 'dark'?'#1D1D20':'#FCFCFD',
        },
        "::-webkit-scrollbar-thumb": {
            background: theme === 'dark'?"#3F3F46":'#CDD5DF',
            zIndex: '0'
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: theme === 'dark'?"#3F3F46":'#CDD5DF'
        }
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
        color: '#fff',
        padding: 0,
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
        padding: 0,
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        display: 'none',
    }),
    input: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: '1.2vh',
        padding: '0.4vh 0.4vh',
    }),
    menu: (styles) => ({
        ...styles,
        background: 'transparent',
        boxShadow: 'none',
        zIndex: '1000',
    }),
}};