import styles from '../styles/Footer.module.scss';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <div className={styles.footer}>
        <div className={styles.top}>
          <Link to='/accounts'>{t("Account Types")}</Link>
          <Link to='/tools/forexHeatMap'>{t("Instruments")}</Link>
          <Link to='/bonuses'>{t("Promotion and bonuses")}</Link>
          <Link to='/about'>{t("About us")}</Link>
          <span className={styles.left_side}>
              {t("© 2024 BoerseLink")}
            </span>
        </div>
        {/* {window.innerWidth > 600 ? (
          <div className={styles.bottom}>
            <span className={styles.left_side}>
              {t("© 2024 BoerseLink")}
            </span>
            <div className={styles.right_side}>
              <span>{t("All Rights Reserved")}</span>
              <span>{t("Privacy Notice")}</span>
              <span>{t("Legal")}</span>
              <span>{t("Site map")}</span>
            </div>
          </div>
        ) : (
          <div className={styles.bottom}>
            <div className={styles.right_side}>
              <span>{t("All Rights Reserved")}</span>
              <span>{t("Privacy Notice")}</span>
              <span>{t("Legal")}</span>
              <span>{t("Site map")}</span>
            </div>
            <span className={styles.left_side}>
              {t("© 2024 BoerseLink")}
            </span>
          </div>
        )} */}
      </div>
    )
}