import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Card, CardContent, Typography, Box, Avatar } from "@mui/material";
import "swiper/scss";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { slides } from "./Slides.jsx";

const Slider = forwardRef((props, ref) => {
  const { onSlideChange } = props;
  const swiperRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useImperativeHandle(ref, () => ({
    slideNext: () => swiperRef.current?.slideNext(),
    slidePrev: () => swiperRef.current?.slidePrev(),
  }));

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth > 1300 ? (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 10000 }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <Card sx={{ maxWidth: 1000, height: 333, margin: "auto", padding: 0, backgroundColor: '#FFF !important' }}>
                <CardContent sx={{ padding: 0 }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Avatar
                      src={slide.avatar}
                      sx={{
                        width: 258,
                        height: 333,
                        marginRight: 3,
                        filter: "grayscale(100%)",
                        transform: "scaleX(-1)",
                        borderRadius: 0,
                        objectFit: "cover",
                      }}
                    />
                    <Box sx={{ flex: 1, borderTop: '1px solid #E2E2E2', paddingTop: '33px', marginRight: '32px' }}>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", marginBottom: 1, fontSize: '25px' }}
                      >
                        {slide.text}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "left", fontWeight: "bold", fontSize: '16px' }}
                      >
                        {slide.name}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={-20}
          slidesPerView={2.5}
          centeredSlides={true}
          autoplay={{ delay: 5000 }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="custom-slide">
              <Card
                sx={{
                  maxWidth: index === swiperRef.current?.activeIndex ? 320 : 280,
                  height: index === swiperRef.current?.activeIndex ? 350 : 350,
                  margin: "auto",
                  padding: "16px",
                  backgroundColor: "#FFF !important",
                  transition: "transform 0.5s ease, opacity 0.5s ease",
                  transform: index === swiperRef.current?.activeIndex ? "scale(1)" : "scale(0.9)",
                  opacity: index === swiperRef.current?.activeIndex ? 1 : 0.7,
                  overflow: "hidden",
                  boxShadow: index === swiperRef.current?.activeIndex ? "0 8px 20px rgba(0, 0, 0, 0.15)" : "none",
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", flex: 1 }}>
                    <Avatar
                      src={slide.avatar}
                      sx={{
                        width: 50,
                        height: 64,
                        marginRight: 3,
                        filter: "grayscale(100%)",
                        transform: "scaleX(-1)",
                        borderRadius: 0,
                        objectFit: "cover",
                      }}
                    />
                    <Box sx={{ flex: 1, height: "100%", paddingTop: "33px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                      <Typography
                        variant="body1"
                        sx={{ textAlign: "left", marginBottom: 3, fontSize: "16px" }}
                      >
                        {slide.text}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "left", fontWeight: "bold", fontSize: "14px" }}
                      >
                        {slide.name}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
});

export default Slider;
