import React, { useState } from "react";
import styles from "./Deposit.module.scss";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask"; // Импортируем InputMask
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

export default function Deposit({
  theme,
  account,
  accounts,
  setSnackType,
  setSnackMessage,
  handleClick,
  handleSetDrawerDeposit,
}) {
  const { t } = useTranslation();
  const [withdrawalType, setWithdrawalType] = useState(0);
  const [amount, setAmount] = useState("");
  const [fullname, setFullName] = useState("");
  const [address, setAddress] = useState("");
  // Добавляем состояние для поля ввода кредитной карты
  const [creditCard, setCreditCard] = useState("");
  const { id } = useParams();

  const Replenish = async () => {
    if (!amount || +amount < 0) {
      setSnackType("error");
      handleClick();
      setSnackMessage(t("Amount Error"));
      return;
    }

    await axios
      .post(
        process.env.REACT_APP_test_url + `/api/contact/email/deposit`,
        {
          platformid: account?.Id,
          account: null,
          type: 3,
          price: +amount,
        },
        {
          headers: {
            Authorization: "Bearer " + secureLocalStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        setSnackType("success");
        setSnackMessage(t("ManagerWill"));
        handleClick();
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className={styles.withdrawal}>
      <div className={styles.withdrawal_title}>
        <div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.6667 9.33325V13.3333M12.6667 13.3333L14 11.8M12.6667 13.3333L11.3334 11.8"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.6667 8.00008C14.6667 5.48592 14.6667 4.22885 13.8857 3.4478C13.1046 2.66675 11.8475 2.66675 9.33337 2.66675H6.66671C4.15255 2.66675 2.89547 2.66675 2.11442 3.4478C1.33337 4.22885 1.33337 5.48592 1.33337 8.00008C1.33337 10.5142 1.33337 11.7713 2.11442 12.5524C2.89547 13.3334 4.15255 13.3334 6.66671 13.3334H9.33337"
                stroke="white"
                stroke-linecap="round"
              />
              <path
                d="M6.66667 10.6667H4"
                stroke="white"
                stroke-linecap="round"
              />
              <path
                d="M8.66671 10.6667H8.33337"
                stroke="white"
                stroke-linecap="round"
              />
              <path
                d="M1.33337 6.66675L14.6667 6.66675"
                stroke="white"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span>{t("Deposit")}</span>
        </div>
        <button onClick={handleSetDrawerDeposit} className={styles.closeButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12 4.00003L4 12M3.87 4L11.8 12"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.withdrawal_body}>
        <div className={styles.withdrawal_form}>
          <div className={styles.amount}>
            <label>{t("Amount of payment")}</label>
            <input
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              placeholder={t("Amount of payment")}
              type="number"
            />
          </div>
          <div className={styles.amount}>
            <label>{t("Full name")}</label>
            <input
              onChange={(e) => setFullName(e.target.value)}
              value={fullname}
              placeholder={t("Full name")}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <button onClick={handleSetDrawerDeposit}>{t("Cancel")}</button>
        <button
          onClick={() => {
            Replenish();
            handleSetDrawerDeposit();
          }}
        >
          {t("Continue")}
        </button>
      </div>
    </div>
  );
}
