import Photo from '../imgs/photo.png';
import Photo1 from '../imgs/photo1.jpg';
import Photo2 from '../imgs/photo2.jpg';
import Photo3 from '../imgs/photo3.jpg';
import Photo4 from '../imgs/photo4.jpg';
import Photo5 from '../imgs/photo5.jpg';
import Photo6 from '../imgs/photo6.png';

export const slides = [
    {
      name: "Brooklyn Simmons",
      text: "“Der in die Plattform integrierte technische Berater Autochartist eröffnet noch profitablere Möglichkeiten.”",
      avatar: Photo,
    },
    {
      name: "Vincent Dupont",
      text: "“Die Plattform erwies sich als sehr benutzerfreundlich und einfach zu navigieren. Als Anfänger fand ich mich dank der intuitiven Benutzeroberfläche schnell zurecht. Ich habe bereits meine erste Auszahlung getätigt, und alles verlief reibungslos.”",
      avatar: Photo1,
    },
    {
      name: "Gustav Müller",
      text: "“Die Plattform hat sich als zuverlässig und stabil erwiesen. Die Transaktionen erfolgen schnell und die Abhebungen sind problemlos. Mein Kapital wächst stetig, was sehr zufriedenstellend ist.”",
      avatar: Photo2,
    },
    {
      name: "Elisabeth Moreau",
      text: "“Dieser Dienst hat mir den Einstieg in den Online-Handel sehr erleichtert. Die Schnittstelle ist einfach und erfordert keine umfangreiche Schulung. Die Gewinne haben meine Erwartungen übertroffen, und ich habe vor, mein Kapital weiter auszubauen.”",
      avatar: Photo3,
    },
    {
      name: "Lorenzo Ricci",
      text: "“Die Leistung der Broker ist hervorragend - sie sind immer bereit, bei der Strategie und Entscheidungsfindung zu helfen. Die Handelsinstrumente sind benutzerfreundlich und bieten vollständigen Zugang zu den erforderlichen Informationen. Ich kann problemlos Geld abheben, und das Geld kommt schnell an.”",
      avatar: Photo4,
    },
    {
      name: "Friedrich Bauer",
      text: "“Die Berater haben immer ein offenes Ohr für meine Fragen und bieten kompetente Lösungen. Die praktischen Trading-Tools machen den Prozess unkompliziert und leicht verständlich. Außerdem sind die Abhebungen schnell, was ein großes Plus ist.”",
      avatar: Photo5,
    },
    {
      name: "Sophie Klein",
      text: "“Die Plattform ist bequem und einfach zu bedienen. Dank der Unterstützung fühlte ich mich schnell sicher in meinem Handeln. Meine Investitionen haben bereits zu positiven Ergebnissen geführt.”",
      avatar: Photo6,
    },
  ];